import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ClientsPage = () => (
  <Layout title="Quak is Everywhere">
    <SEO title="Clients" />

    <h2>Web App</h2>
    <p>
      <a href="https://app.quak.sh" target="_blank" rel="noopener noreferrer">
        Go to Web App
      </a>
    </p>

    <h2>Command Line</h2>
    <p>
      <a href="#">Download Command Line Package (CLI)</a>
    </p>

    <h2>Slack App</h2>
    <p>
      Please install the Quak Slack App via the Web App here.{" "}
      <a
        href="https://app.quak.sh/slack"
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to Web App
      </a>
    </p>

    <h2>Node-RED</h2>
    <p>
      If you want to integrate Quak into your smart home with Node-RED, please
      have a look at our flows over here.
    </p>

    <h2>IFTTT</h2>
    <p>
      Unfortunately, Quak is not integrated natively into IFTTT at the moment,
      but you can use our <Link to="/api/">API</Link> and{" "}
      <a
        href="https://ifttt.com/maker_webhooks"
        target="_blank"
        rel="noopener noreferrer"
      >
        IFTTT Webhooks
      </a>{" "}
      to play notifications. See how to do it.
    </p>

    <h2>Zapier</h2>
    <p>Zapier integration is coming soon.</p>

    <h2>iPhone/Apple Watch</h2>
    <p>iOS/watchOS is coming soon.</p>

    <h2>Mac/Windows/Linux</h2>
    <p>Multiplatform desktop app is coming soon.</p>
  </Layout>
)

export default ClientsPage
